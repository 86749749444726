import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";

export const imageFields = graphql`
  fragment imageFields on ImageSharp {
    fluid(maxWidth: 500) {
      ...GatsbyImageSharpFluid_tracedSVG
    }
  }
`;

const Phone = () => (
  <StaticQuery
    query={graphql`
      query PhoneImageQuery {
        androidImage: imageSharp(
          fluid: { originalName: { regex: "/android.jpg/" } }
        ) {
          ...imageFields
        }
      }
    `}
    render={(data) => (
      <PhoneWrapper>
        <Img fluid={data.androidImage.fluid} alt={data.androidImage.alt} />
      </PhoneWrapper>
    )}
  />
);

const PhoneWrapper = styled.div`
  flex: 1;
  max-width: 360px;
  @media (max-width: 960px) {
    width: 70%;
    min-width: 200px;
    margin: 0 auto;
    position: relative;
    left: 1rem;
  }
`;

export default Phone;
