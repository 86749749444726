import styled from "styled-components";

export const ContentWrapper = styled.div`
  flex: 2;
  text-align: right;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-size: 38px;
  text-align: right;
  color: #36a3ba;
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 1.2em;
  }
`;

export const Paragraph = styled.h4`
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 20px;
  text-align: right;
  color: #555555;
  max-width: 550px;
  margin: 0 0 1rem auto;
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 1em;
  }
  @media (max-width: 960px) {
    max-width: 100%;
    margin: 0 0 2rem 0;
  }
`;
