import React from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { compose } from "recompose";
import {
  Input,
  Message,
  Button,
  Error,
  Flex,
  InputField,
  SubWrapper,
  Platforms,
  Text,
  Os,
} from "./styles";

const Subscribe = ({
  errors,
  touched,
  isSubmitting,
  values,
  platforms,
  setFieldValue,
  handleBlur,
}) => (
  <SubWrapper>
    <Form>
      <Platforms>
        <Text>نظام التشغيل:</Text>
        {platforms.map(({ id, name }) => (
          <Os key={id} os={name}>
            <input
              type="radio"
              checked={values.OS === name}
              onChange={() => setFieldValue("OS", name)}
              onBlur={handleBlur}
              name="OS"
              value={name}
              os={name}
            />
            <span />
          </Os>
        ))}
      </Platforms>
      <InputField>
        <Input
          as={Field}
          type="text"
          name="FNAME"
          placeholder="الإسم الأول"
          autoComplete="new-pass"
        />
        {touched.FNAME && errors.FNAME && <Error>{errors.FNAME}</Error>}
      </InputField>
      <InputField>
        <Input
          as={Field}
          type="text"
          name="LNAME"
          placeholder="إسم العائلة"
          autoComplete="new-pass"
        />
        {touched.LNAME && errors.LNAME && <Error>{errors.LNAME}</Error>}
      </InputField>
      {values.status === "success" && (
        <Message>
          شكرا لك! سوف تتلقى دعوتك فور جاهزية التطبيق قريبا
          <span role="img" aria-label="successfully subscribed">
            🎉
          </span>
        </Message>
      )}
      {values.status === "error" && (
        <Error dangerouslySetInnerHTML={{ __html: values.msg }} />
      )}
      <Flex>
        <InputField>
          <Input
            as={Field}
            type="email"
            name="email"
            placeholder="البريد الإلكتروني"
            autoComplete="new-pass"
          />
          {touched.email && errors.email && <Error>{errors.email}</Error>}
        </InputField>
        <Button type="submit" disabled={isSubmitting}>
          أرسل
        </Button>
      </Flex>
    </Form>
  </SubWrapper>
);

const enhance = compose(
  withFormik({
    mapPropsToValues: () => ({
      FNAME: "",
      LNAME: "",
      email: "",
      status: "",
      OS: "android",
      msg: "",
    }),
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string()
          .email("يرجى إدخال بريد الكتروني صحيح")
          .required("يرجى إدخال عنوان البريد الإلكتروني"),
        FNAME: Yup.string().required("يرجى ادخال الإسم الأول"),
        LNAME: Yup.string().required("يرجى ادخال إسم العائلة"),
        OS: Yup.string().required("يرجى اختيار نظام التشغيل الخاص بك"),
      }),
    handleSubmit: async (values, { setErrors, setSubmitting, setValues }) => {
      try {
        setSubmitting(true);
        const { FNAME, LNAME, OS, email } = values;
        const res = await addToMailchimp(email, {
          FNAME,
          LNAME,
          OS,
          pathname: document.location.pathname,
        });
        if (res.result === "success") {
          setValues({
            status: "success",
            msg: res.msg,
            FNAME,
            LNAME,
            OS,
            email,
          });
          setSubmitting(false);
        } else {
          setValues({ status: "error", msg: res.msg, FNAME, LNAME, OS, email });
          setSubmitting(false);
        }
      } catch (err) {
        setErrors({ message: err, status: "error" });
        setSubmitting(false);
      }
    },
  })
);

export default enhance(Subscribe);
