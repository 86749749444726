import React from 'react'
import Details from './Details'
import Phone from './Phone'
import { Wrapper } from './styles'

const Content = ({ platform, handlePlatform }) => (
	<Wrapper>
		<Details />
		<Phone  />
	</Wrapper>
)

export default Content
