import React from "react";
import Subscribe from "./Subscribe";
import { ContentWrapper, Title, Paragraph } from "./styles";

const platforms = [
  {
    id: 1,
    name: "android",
  },
  {
    id: 2,
    name: "ios",
  },
];

const Details = () => (
  <ContentWrapper>
    <Title>النسخة التجريبية من تطبيق مكتبة</Title>
    <Paragraph>
      كن أول من يستخدم النسخة التجريبية من تطبيق مكتبة! سوف تتلقى دعوتك فور
      جاهزية التطبيق قريبا.{" "}
      <span role="img" aria-label="be on the first to try">
        😎
      </span>
    </Paragraph>
    <Subscribe platforms={platforms} />
  </ContentWrapper>
);

export default Details;
