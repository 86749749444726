import React from 'react'
import { Layout } from '../components/common/'
import Brand from '../components/theme/Brand'
import Content from '../components/theme/Content'

const IndexPage = () => (
	<Layout>
		<Brand />
		<Content />
	</Layout>
)

export default IndexPage
