import styled from "styled-components";

export const SubWrapper = styled.div`
  text-align: right;
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0.5rem;
    width: 50%;
  }
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Input = styled.div`
  width: 100%;
  border: 2px solid #555555;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.8rem 0.5rem;
  text-align: right;
`;

export const Button = styled.button`
  background: #36a3ba;
  border-radius: 8px;
  padding: 0.8rem 2rem;
  color: #fff;
  margin-right: 2rem;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @media (max-width: 960px) {
    margin-right: unset;
  }
`;

export const Message = styled.div`
  padding: 1rem 0;
`;

export const Error = styled.span`
  color: #ff4136;
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const Platforms = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #555555;
  margin: 0 0 0 1rem;
`;

export const Os = styled.label`
  cursor: pointer;
  &:last-child {
    margin-right: 0.5rem;
  }
  input[type="radio"] {
    display: none;
    + span:before {
      content: "";
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      transition: 0.5s;
      padding: 1rem;
      background-size: 40%;
      background-position: center;
      background-repeat: no-repeat;
      ${({ os }) =>
        os === "ios"
          ? `
                background-image: url('/icons/ios.svg')
            `
          : `
                background-image: url('/icons/android.svg')
            `}
    }
  }

  input[type="radio"]:checked + span:before {
    content: "";
    background: #36a3ba;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    transition: 0.5s;
    padding: 1rem;
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 18px 40px rgba(0, 0, 0, 0.08);
    ${({ os }) =>
      os === "ios"
        ? `
            background-image: url('/icons/white-ios.svg')
        `
        : `
            background-image: url('/icons/white-android.svg')
        `}
  }
`;
