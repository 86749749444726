import React from "react";
import styled from "styled-components";
import logo from "../../../../static/logo.png";

const Brand = () => (
  <Logo>
    <img src={logo} alt="Maktaba Logo" height="80" />
  </Logo>
);

const Logo = styled.div`
  padding: 2rem 0;
  text-align: right;
  @media (max-width: 960px) {
    text-align: center;
  }
`;

export default Brand;
